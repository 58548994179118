/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
/* LEVEL 1 */
div.sub1 {
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 2;
}
.sub1 > .item {
  border-right: 1px solid #4a4430;
  padding: 0 14px;
}
.sub1 > .item.init {
  padding-left: 0;
}
.sub1 > .item.exit {
  border: none;
  padding-right: 0;
}
div.sub1 .menu {
  float: left;
  line-height: 1;
  font-size: 15px;
  color: #4a4430;
}
div.sub1 .menu:hover,
div.sub1 .menu:focus {
  color: #fff;
}
div.sub1 a.path {
  color: #fff;
}
/* LEVEL 2 */
div.sub2 {
  float: right;
  max-width: 700px;
}
.sub2 > .item {
  border-right: 1px solid #4a4430;
  padding: 0 14px;
  margin: 7px 0 4px;
}
.sub2 > .item.init {
  padding-left: 0;
}
.sub2 > .item.exit {
  border: none;
  padding-right: 0;
}
div.sub2 .menu {
  font-size: 18px;
  text-transform: uppercase;
  color: #4a4430;
  line-height: 19px;
  font-family: 'Droid Serif', helvetica, sans-serif;
}
div.sub2 .menu:hover,
div.sub2 .menu:focus {
  color: #fff;
}
div.sub2 a.path {
  color: #fff;
}
/* LEVEL 3 */
#services {
  float: left;
  color: #fff;
  margin-top: 5px;
  margin-bottom: 17px;
}
#services .meta {
  font-size: 16px;
  color: #fff;
  border-right: 2px solid #fff;
  padding-right: 5px;
  line-height: 1;
}
#services .meta:hover,
#services .meta:focus {
  color: #4a4430;
}
#services .meta.service_copyright {
  pointer-events: none;
  padding-right: 0;
  border: none;
}
#services .meta.auth {
  display: inline;
}
/******* layout-large.less 2013-1-16 *******/
.desk {
  max-width: 96%;
}
#home {
  width: 360px;
  margin-top: 30px;
  margin-bottom: -15px;
}
.cb-elastic-content-small #home {
  margin-top: 15px;
  width: 260px;
  margin-bottom: -12px;
}
#multimood {
  margin-top: 118px;
}
h2 {
  font-size: 36px;
}
div.text p.norm {
  font-size: 18px;
}
@media only screen and (max-width: 1080px) {
  #home {
    width: 300px;
  }
}
.bookingmaskwidth {
  left: 0;
  width: 100%;
}
.bookingmaskwidth.cb-navi-fixed {
  width: 1080px;
  left: 50%;
  margin-left: -540px;
}
@media only screen and (max-width: 1124px) {
  .bookingmaskwidth.cb-navi-fixed {
    width: 96%;
    left: 2%;
    margin-left: 0;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0.90909091%;
  margin-left: 0.90909091%;
}
.area .part,
.area > .grid table {
  margin-right: 0.90909091%;
  margin-left: 0.90909091%;
  width: 98.18181818%;
}
.area .tiny {
  width: 48.18181818%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 1.81818182%;
  margin-left: 1.81818182%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 96.36363636%;
}
.area > .slim .tiny {
  width: 46.36363636%;
}
.cb-layout2 .main {
  width: 100%;
}
.cb-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main h2,
.cb-layout2 .main .foot {
  margin-right: 0.90909091%;
  margin-left: 0.90909091%;
}
.cb-layout2 .main .part,
.cb-layout2 .main > .grid table {
  margin-right: 0.90909091%;
  margin-left: 0.90909091%;
  width: 98.18181818%;
}
.cb-layout2 .main .tiny {
  width: 31.51515152%;
}
.cb-layout2 .main > .slim {
  width: 33.33333333%;
}
.cb-layout2 .main > .slim h2,
.cb-layout2 .main > .slim .foot,
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  margin-right: 2.72727273%;
  margin-left: 2.72727273%;
}
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  width: 94.54545455%;
}
.cb-layout2 .main > .slim .tiny {
  width: 94.54545455%;
}
.north {
  width: 100%;
}
.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.north h2,
.north .foot {
  margin-right: 0.90909091%;
  margin-left: 0.90909091%;
}
.north .part,
.north > .grid table {
  margin-right: 0.90909091%;
  margin-left: 0.90909091%;
  width: 98.18181818%;
}
.north .tiny {
  width: 31.51515152%;
}
.north > .slim {
  width: 33.33333333%;
}
.north > .slim h2,
.north > .slim .foot,
.north > .slim .part,
.north > .slim.grid table {
  margin-right: 2.72727273%;
  margin-left: 2.72727273%;
}
.north > .slim .part,
.north > .slim.grid table {
  width: 94.54545455%;
}
.north > .slim .tiny {
  width: 94.54545455%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.main {
  width: 101.85185185%;
  margin-left: -0.92592593%;
}
.cb-layout2 .main {
  width: 101.85185185%;
  margin-left: -0.92592593%;
}
.north {
  width: 101.85185185%;
  margin-left: -0.92592593%;
}
.cb-layout2 div.main div.unit.wide {
  width: 66.54545455%;
}
.cb-layout2 div.main div.unit.wide div.part {
  margin-left: 1.36612022%;
  margin-right: 1.36612022%;
  width: 97.26775956%;
}
.cb-layout2 div.main div.unit.wide div.part.tiny {
  width: 47.26775956%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -760px;
  width: 1520px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */